.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
}
.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
table th {
  font-weight: 500;
}

.logResultData {
    padding-left: 0rem !important;
    
  
}

.card-subtitle {
  margin-top:0rem !important;
  margin-bottom:0rem !important;
}


.no-wrap.mt-3.align-middle.table.table-borderless {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}